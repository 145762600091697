import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    BlogCardBox,
    BlogThumb,
    BlogContent,
    BlogMeta,
    Author,
    Date,
    Category,
    Title,
} from "./style";
const BlogCard = ({ variant, title, image, link }) => {
    return (
        <BlogCardBox variant={variant}>
            <BlogThumb className='blog-thumb'>
                <Link to={link}>
                    <img
                        src={image}
                        alt={title}
                        style={{
                            borderRadius: "10px",
                            height: "80px",
                            width: "150px",
                            maxWidth: "none",
                        }}
                    />
                </Link>
            </BlogThumb>
            <BlogContent>
                <Title>
                    <Link to={link}>{title}</Link>
                </Title>
            </BlogContent>
        </BlogCardBox>
    );
};
BlogCard.propTypes = {
    variant: PropTypes.oneOf(["horizontal", "vertical"]),
    title: PropTypes.string,
    link: PropTypes.string,
    date: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.object,
};
BlogCard.defaultProps = {
    variant: "horizontal",
};
export default BlogCard;
